import { CheckCircleIcon } from "Icons/CheckCircle";
import { CheckCircleFilledIcon } from "Icons/CheckCircleFilled";

import { css } from "@emotion/react";

import { RectangleToast } from "components/shared/library/RectangleToast";
import { Typography } from "components/shared/library/Typography";

import { down } from "utils/mediaQueryStrings";

import { blue, uiGray } from "constants/colors";

const style = {
  wrapper: css({
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    width: "-webkit-fill-available",
  }),
  matchingKeywords: css({
    display: "flex",
    flexWrap: "wrap",
    rowGap: 14,
    columnGap: 64,
  }),
  keywords: css({
    width: "calc(25%)",
    minWidth: 200,
    [down("mobile")]: {
      width: "calc(100%)",
    },
    marginBottom: 12,
    boxSizing: "border-box",
    display: "flex",
    gap: 9,
  }),
  tip: css({
    display: "flex",
    flexDirection: "column",
    gap: 2,
    padding: "0 30px 0 30px",
  }),
  toast: css({
    flexDirection: "column",
    gap: 16,
  }),
};

type Props = {
  matchingKeywords: string[];
  missingKeywords: string[];
};

export const KeywordsMatching = ({
  matchingKeywords,
  missingKeywords,
}: Props) => {
  return (
    <div css={style.wrapper}>
      <div css={style.matchingKeywords}>
        {[...matchingKeywords, ...missingKeywords].map((keyword, index) => (
          <div key={index} css={style.keywords}>
            <div>
              {matchingKeywords.includes(keyword) ? (
                <CheckCircleFilledIcon fill={blue[800]} stroke={blue[800]} />
              ) : (
                <CheckCircleIcon stroke={uiGray[70]} />
              )}
            </div>
            <div>
              <Typography weight="medium">{keyword}</Typography>
            </div>
          </div>
        ))}
      </div>
      {matchingKeywords.length /
        (matchingKeywords.length + missingKeywords.length) <
        0.5 && (
        <RectangleToast
          customCss={style.toast}
          toastType="tip"
          title="Quick tip"
        >
          <Typography color={blue[800]} weight="medium">
            Add 2 to 3 more keywords and get your score above 70% to increase
            your chances of getting noticed by ATS, recruiters, and hiring
            managers!
          </Typography>
        </RectangleToast>
      )}
    </div>
  );
};
