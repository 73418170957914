import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const ArrowNarrowLeftIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[80],
  fill = "none",
  strokeWidth = 1.5,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3327 8H2.66602M2.66602 8L6.66602 12M2.66602 8L6.66602 4"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
