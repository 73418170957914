import { Lock02Icon } from "Icons/Lock02";

import { css } from "@emotion/react";

import { Badge } from "components/shared/library/Badge";
import { Divider } from "components/shared/library/Divider";
import { Typography } from "components/shared/library/Typography";
import { UpgradePlanButton } from "components/shared/ribbonPro/UpgradePlanButton";

import { ResumeFeedback } from "apis/resumeFeedback";

import { down } from "utils/mediaQueryStrings";

import { white } from "constants/colors";

import { KeywordsMatching } from "./KeywordsMatching";
import { SuggestedFeedback } from "./SuggestedFeedback";

const style = {
  overusage: css({
    filter: "blur(4px)",
  }),
  upgradeButton: css({
    zIndex: 1,
    left: "50%",
    transform: "translate(-50%, -100%)",
    top: "calc(100vh - 40px)",
    position: "fixed",
  }),
  wrapper: css({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: "-webkit-fill-available",
    background: white,
    borderRadius: 16,
    padding: 24,
  }),
  badges: css({
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
    width: "-webkit-fill-available",
  }),
  section: css({
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    gap: 16,
  }),
  noLineBreak: css({
    display: "inline",
  }),
  divider: css({
    margin: 0,
  }),
  badge: css({
    whiteSpace: "nowrap",
    [down("mobile")]: {
      whiteSpace: "initial",
    },
  }),
  toast: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
};

type Props = {
  feedback: ResumeFeedback;
  firstName: string;
  showUpgradeBtn: boolean;
};

export const FeedbackDisplay = ({
  feedback,
  firstName,
  showUpgradeBtn,
}: Props) => {
  return (
    <>
      {showUpgradeBtn && (
        <UpgradePlanButton
          customCss={{ wrapper: style.upgradeButton }}
          color="black"
          IconLeft={Lock02Icon}
        >
          Upgrade to unlock feedback
        </UpgradePlanButton>
      )}
      <div css={[style.wrapper, showUpgradeBtn ? style.overusage : undefined]}>
        <Typography
          size="XL"
          weight="bold"
        >{`${firstName}'s Resume+ feedback`}</Typography>
        <Typography>Your resume is looking great! </Typography>
        <Typography>
          We made some notes and suggestions to make it score even higher with
          job applications.
        </Typography>
        {feedback.matchingRoles.length > 0 && (
          <div css={style.section}>
            <Typography size="M" weight="bold">
              Your resume matches roles like
            </Typography>
            <div css={style.badges}>
              {feedback.matchingRoles.map((role) => (
                <Badge
                  key={role}
                  label={role}
                  color="blue"
                  customCss={style.badge}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div css={[style.wrapper, showUpgradeBtn ? style.overusage : undefined]}>
        {feedback.improvements.length > 0 && (
          <div css={style.section}>
            <Typography size="M" weight="bold">
              Suggested feedback
            </Typography>
            {feedback.improvements.map((improvement) => (
              <>
                <SuggestedFeedback improvement={improvement} />
                <Divider customCss={style.divider} />
              </>
            ))}
          </div>
        )}
      </div>
      <div css={[style.wrapper, showUpgradeBtn ? style.overusage : undefined]}>
        {(feedback.matchingKeywords.length > 0 ||
          feedback.missingKeywords.length > 0) && (
          <div css={style.section}>
            <Typography size="M" weight="bold">
              Keywords
            </Typography>
            <div>
              <Typography
                tag="p"
                customCss={style.noLineBreak}
              >{`Your resume has `}</Typography>
              <Typography tag="p" weight="bold" customCss={style.noLineBreak}>
                {`${feedback.matchingKeywords.length} of ${
                  feedback.matchingKeywords.length +
                  feedback.missingKeywords.length
                } `}
              </Typography>
              <Typography tag="p" customCss={style.noLineBreak}>
                keywords that appear most often in matching roles.
              </Typography>
              <KeywordsMatching
                matchingKeywords={feedback.matchingKeywords}
                missingKeywords={feedback.missingKeywords}
              />
            </div>
          </div>
        )}
      </div>
      <div css={[style.wrapper, showUpgradeBtn ? style.overusage : undefined]}>
        {feedback.missingSkills.length > 0 && (
          <div css={style.section}>
            <Typography size="M" weight="bold">
              Skills to add
            </Typography>
            <div css={style.badges}>
              {feedback.missingSkills.map((skill) => (
                <Badge key={skill} label={skill} customCss={style.badge} />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
