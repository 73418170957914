import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const ChevronRightDoubleFilledIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[80],
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 17L11 12L6 7M13 17L18 12L13 7"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
