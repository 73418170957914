import { ResumePublicPage } from "components/resume/ResumePublicPage";
import { Head } from "components/shared/layout/Head";
import { PageLayout } from "components/shared/layout/PageLayout";

export default function ResumePublic() {
  return (
    <>
      <Head pageTitle="Resume+" />
      <ResumePublicPage />
    </>
  );
}

ResumePublic.getLayout = function getLayout(page: React.ReactNode) {
  return <PageLayout showRightPanel>{page}</PageLayout>;
};
