import { ArrowNarrowLeftIcon } from "Icons/ArrowNarrowLeft";
import { ChevronLeftDoubleFilledIcon } from "Icons/ChevronLeftDoubleFilled";
import { ChevronRightDoubleFilledIcon } from "Icons/ChevronRightDoubleFilled";
import { Menu03FilledIcon } from "Icons/Menu03Filled";
import { RibbonLogoIcon } from "Icons/RibbonLogo";
import { SearchIcon } from "Icons/Search";

import { PropsWithChildren, useState } from "react";
import { useSelector } from "react-redux";

import { css } from "@emotion/react";

import { useRouter } from "next/router";
import { selectNewNotifsCount } from "store/notificationsSlice";

import { SearchBar } from "components/search/SearchBar";
import { Typography } from "components/shared/library/Typography";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { useCloseOnClickOutside } from "utils/libraryHooks";
import { down, up } from "utils/mediaQueryStrings";
import { getHomeUrl } from "utils/urls";

import {
  SEARCH_BAR_APPEARANCE,
  SLIDE_TRANSITION_DURATION,
  SLIDE_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { purple, uiGray, white } from "constants/colors";

import { Button } from "../library/Button";
import { ButtonWrapper } from "../library/ButtonWrapper";

const getStyles = (
  mobileRemoveRightSection: boolean,
  leftPanelStatus: string
) => {
  return {
    headerSection: css({
      height: 60,
      background: white,
      borderBottom: `1px solid ${uiGray[10]}`,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      flexShrink: 0,
      zIndex: 2,
      justifyContent: "space-between",
    }),
    main: css({
      overflow: "hidden",
      flex: 1,
      background: uiGray.pageBackground,
      backgroundImage: 'url("/images/Pattern.png")',
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat",
      [up("desktop")]: {
        backgroundSize: "100% auto",
      },
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    }),
    content: css({
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "-webkit-fill-available",
      overflowX: "hidden",
      height: "-webkit-fill-available",
      [down("mobile")]: {
        paddingBottom: 80,
      },
    }),
    secondaryText: css({
      [down("mobile")]: {
        display: "none",
      },
    }),
    rightSection: css({
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      [down("mobile")]: {
        display: mobileRemoveRightSection ? "none" : "flex",
      },
    }),
    displayPanel: css({
      margin: "0 4px",
    }),
    logo: css({
      [down("mobile")]: {
        display: "none",
      },
    }),
    title: css({
      [up("mobile")]: {
        display:
          leftPanelStatus === "locked" || leftPanelStatus === "collapsed"
            ? "block"
            : "none",
      },
    }),
    hide: css({ display: "none" }),
    navigationTitle: css({
      display: "flex",
      alignItems: "center",
      gap: 8,
      [down("mobile")]: {
        display: "none",
      },
    }),
    navigation: css({
      display: "flex",
      alignItems: "center",
    }),
    search: css({
      [up("desktop")]: {
        display: "none",
      },
      animation: `${SEARCH_BAR_APPEARANCE} ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION} forwards`,
    }),
    topNavContent: css({
      alignSelf: "flex-start",
      paddingTop: 10,
      width: "-webkit-fill-available",
    }),
    searchIcon: css({
      padding: 10,
      [up("desktop")]: {
        display: "none",
      },
    }),
    redDot: css({
      position: "relative",
      left: -18,
      top: -12,
    }),
    hamburgerIcon: css({
      display: "flex",
      gap: 8,
      alignItems: "center",
      [up("mobile")]: {
        display: "none",
      },
    }),
    SSR: css({
      // in SSR we want to show the hamburgeIcon only on mobile
      [up("mobile")]: {
        display: "none",
      },
    }),
  };
};

type Props = {
  pageTitle: string;
  secondaryText?: string;
  navigateBackTo?: string;
  mobileRemoveRightSection?: boolean;
  rightSection?: React.ReactNode;
  customTopNav?: React.ReactNode;
  hideSearch?: boolean;
};

export const TopNav = ({
  children,
  pageTitle,
  secondaryText,
  navigateBackTo,
  rightSection,
  customTopNav,
  mobileRemoveRightSection = false,
  hideSearch = false,
}: PropsWithChildren<Props>) => {
  const router = useRouter();
  const [hideSearchIcon, setHideSearchIcon] = useState(false);
  const notifCount = useSelector(selectNewNotifsCount);
  const {
    leftPanelStatus,
    setLeftPanelStatus,
    leftPanelDisplayButtonId,
    isLockable,
  } = usePageLayoutContext();
  const style = getStyles(mobileRemoveRightSection, leftPanelStatus);

  useCloseOnClickOutside(
    hideSearchIcon,
    setHideSearchIcon,
    "search-bar-wrapper"
  );
  return (
    <div css={style.main}>
      <div css={style.headerSection}>
        {(leftPanelStatus === "collapsed" || leftPanelStatus === "SSR") && (
          <div
            css={[
              style.hamburgerIcon,
              leftPanelStatus === "SSR" ? style.SSR : undefined,
            ]}
          >
            <Button
              color="gray"
              isSquare
              background="outlined"
              size="extra-small"
              onClick={() =>
                setLeftPanelStatus(isLockable ? "locked" : "preview")
              }
              IconLeft={Menu03FilledIcon}
              ariaLabel="Preview"
              customCss={{ wrapper: style.displayPanel }}
            />
            {!!notifCount && notifCount > 0 && (
              <div css={style.redDot}>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill={purple[500]}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5" cy="5" r="5" />
                </svg>
              </div>
            )}
            <ButtonWrapper
              onClick={() => {
                router.push(getHomeUrl());
              }}
              customCss={style.logo}
              ariaLabel="ribbon-logo"
            >
              <RibbonLogoIcon width={24} height={24} />
            </ButtonWrapper>
            <div css={style.navigation}>
              {navigateBackTo && (
                <Button
                  ariaLabel="Back"
                  color="black"
                  size="small"
                  background="ghost"
                  IconLeft={ArrowNarrowLeftIcon}
                  onClick={() => router.push(navigateBackTo)}
                />
              )}
              {customTopNav === undefined && (
                <Typography
                  weight="bold"
                  size="S"
                  color={uiGray[100]}
                  customCss={hideSearchIcon ? style.hide : style.title}
                >
                  {pageTitle}
                </Typography>
              )}
            </div>
          </div>
        )}
        {customTopNav ? (
          <div css={style.topNavContent}>{customTopNav}</div>
        ) : (
          <>
            {leftPanelStatus === "preview" && (
              <Button
                color="gray"
                isSquare
                background="outlined"
                size="extra-small"
                onClick={() =>
                  setLeftPanelStatus(isLockable ? "locked" : "collapsed")
                }
                IconLeft={
                  isLockable
                    ? ChevronRightDoubleFilledIcon
                    : ChevronLeftDoubleFilledIcon
                }
                ariaLabel={isLockable ? "Lock" : "Collapse"}
                id={leftPanelDisplayButtonId}
                customCss={{ wrapper: style.displayPanel }}
              />
            )}
            <div css={style.navigationTitle}>
              {navigateBackTo && leftPanelStatus !== "collapsed" && (
                <Button
                  ariaLabel="Back"
                  color="black"
                  size="small"
                  background="ghost"
                  IconLeft={ArrowNarrowLeftIcon}
                  onClick={() => router.push(navigateBackTo)}
                />
              )}
              <Typography
                weight="bold"
                size="S"
                color={uiGray[100]}
                customCss={hideSearchIcon ? style.hide : style.title}
              >
                {pageTitle}
              </Typography>
            </div>

            <Typography
              customCss={style.secondaryText}
              weight="medium"
              size="S"
              color={uiGray[50]}
            >
              {secondaryText}
            </Typography>
            <div
              css={
                hideSearchIcon || !rightSection
                  ? style.hide
                  : style.rightSection
              }
            >
              {rightSection}
            </div>
            {hideSearch && (
              <>
                <div css={hideSearchIcon ? style.hide : style.searchIcon}>
                  {!hideSearchIcon && (
                    <ButtonWrapper
                      onClick={() => {
                        setHideSearchIcon(true);
                      }}
                    >
                      <SearchIcon stroke={uiGray[80]} width={24} height={24} />
                    </ButtonWrapper>
                  )}
                </div>
                <div
                  css={
                    !hideSearchIcon
                      ? style.hide
                      : [style.search, style.topNavContent]
                  }
                  id={"search-bar-wrapper"}
                >
                  {hideSearchIcon && <SearchBar isFullWidth />}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div css={style.content} id="top-nav-content">
        {children}
      </div>
    </div>
  );
};
