import { css } from "@emotion/react";

import { RectangleToast } from "components/shared/library/RectangleToast";
import { Typography } from "components/shared/library/Typography";

import { Improvement } from "apis/resumeFeedback";

import { blue, uiGray } from "constants/colors";

const style = {
  wrapper: css({
    margin: 16,
    display: "flex",
    alignItems: "stretch",
    gap: 16,
    width: "-webkit-fill-available",
  }),
  content: css({
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1, // The content div takes up remaining space
  }),
  verticalLine: css({
    width: "4px",
    backgroundColor: uiGray[10], // Adjust color as needed
  }),
  italics: css({
    fontStyle: "italic",
  }),
  tip: css({
    display: "flex",
    flexDirection: "column",
    gap: 2,
    padding: "0 30px 0 30px",
  }),
  toast: css({
    flexDirection: "column",
    gap: 16,
  }),
  container: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
};

type Props = {
  improvement: Improvement;
};

export const SuggestedFeedback = ({ improvement }: Props) => {
  return (
    <div>
      <div css={style.wrapper}>
        <div css={style.verticalLine}></div>
        <div css={style.content}>
          <div>
            <Typography size="XS" weight="medium" color={uiGray[50]}>
              What you wrote
            </Typography>
          </div>
          <div>
            <Typography
              size="S"
              weight="medium"
              color={uiGray[70]}
              customCss={style.italics}
            >
              {improvement.originalText}
            </Typography>
          </div>
        </div>
      </div>
      <div css={style.container}>
        <Typography size="S" weight="medium" color={uiGray[90]}>
          {improvement.explanation}
        </Typography>
        <RectangleToast
          customCss={style.toast}
          toastType="tip"
          title="Try this instead"
        >
          <Typography color={blue[800]} weight="medium">
            {improvement.suggestedImprovement}
          </Typography>
        </RectangleToast>
      </div>
    </div>
  );
};
