import { IconProps } from "Icons/types";

import { black } from "constants/colors";

export const Lightbulb02 = ({
  width = 24,
  height = 24,
  stroke = black,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.12"
      d="M14 17.6587C13.3744 17.8798 12.7013 18.0001 12 18.0001C11.2987 18.0001 10.6256 17.8798 10 17.6587V22.0001H14V17.6587Z"
      fill="black"
    />
    <path
      d="M10 17.6586V20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20V17.6586M12 2V3M3 12H2M5.5 5.5L4.8999 4.8999M18.5 5.5L19.1002 4.8999M22 12H21M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
