
import { ResumeFeedback } from "apis/resumeFeedback";

const softwareEngineerResumeFeedback: ResumeFeedback = {
  filename: "John_Doe_Resume.pdf",
  matchingRoles: ["Software Engineer", "Web Developer", "Frontend Engineer"],
  improvements: [
    {
      originalText:
        "Worked on some apps and websites. Designed how they would look and stuff. Came up with ideas and drawings for the interfaces. Showed them to the bosses and engineers to try to get built.",
      suggestedImprovement:
        "Led the end-to-end design process for multiple digital products by conducting user research, creating flows/wireframes, building interactive prototypes, gathering feedback through usability testing, and working closely with engineering teams to implement pixel-perfect experiences.",
      explanation:
        "Use clear language with stronger verbs like ‘excel’ and ‘drive’. Stick with industry jargon when crafting a professional summary of your role.",
    },
  ],
  missingSkills: ["React", "Node.js", "Database Design"],
  matchingKeywords: ["JavaScript", "Agile", "Python", "Java"],
  missingKeywords: ["Machine Learning", "Big Data", "Software Development"],
  seenBy: undefined,
  createdAt: "2023-11-28T09:45:00Z",
};

const marketingLeadResumeFeedback: ResumeFeedback = {
  filename: "Jane_Smith_Resume.pdf",
  matchingRoles: [
    "Marketing Lead",
    "Brand Manager",
    "Digital Marketing Manager",
  ],
  improvements: [
    {
      originalText:
        "Managed marketing team. Oversaw social media campaigns and worked on increasing brand awareness. Coordinated with sales and product teams.",
      suggestedImprovement:
        "Directed a dynamic marketing team, spearheading innovative social media campaigns that boosted brand visibility by 35%. Fostered cross-functional collaboration with sales and product teams to align marketing strategies with business objectives, resulting in a 20% increase in lead conversion.",
      explanation:
        "Enhance your description by quantifying achievements and using action-oriented language. Clearly articulate the impact of your work on the business's overall goals.",
    },
  ],
  missingSkills: ["SEO Optimization", "Data Analytics", "Content Strategy"],
  matchingKeywords: [
    "Brand Development",
    "Social Media Marketing",
    "Lead Generation",
  ],
  missingKeywords: [
    "Public Relations",
    "Market Research",
    "Customer Segmentation",
  ],

  createdAt: "2023-12-02T10:30:00Z",
};

const uxUiDesignerResumeFeedback: ResumeFeedback = {
  filename: "Alex_Johnson_Resume.pdf",
  matchingRoles: ["UX/UI Designer", "Product Designer", "Interaction Designer"],
  improvements: [
    {
      originalText:
        "Designed interfaces for various apps. Worked closely with developers. Made sure the design was user-friendly.",
      suggestedImprovement:
        "Conceptualized and implemented visually appealing user interfaces for multiple mobile and web applications, focusing on user-centric design principles. Collaborated effectively with cross-functional teams, including developers, to ensure seamless integration of design and functionality, achieving a 40% improvement in user engagement metrics.",
      explanation:
        "Provide specific details about your design projects and emphasize collaboration. Highlight the outcome of your designs in terms of user engagement or satisfaction.",
    },
  ],
  missingSkills: ["Prototyping", "User Testing", "Information Architecture"],
  matchingKeywords: ["Design Thinking", "Figma", "Adobe Creative Suite"],
  missingKeywords: ["Accessibility Standards", "Motion Design", "3D Design"],

  createdAt: "2023-11-30T11:55:00Z",
};

export const publicSampleResumes = {
  "1": softwareEngineerResumeFeedback,
  "2": marketingLeadResumeFeedback,
  "3": uxUiDesignerResumeFeedback,
};

export const publicSampleResumeOptions = [
  { name: "1", label: "Software Engineer" },
  { name: "2", label: "Marketing Lead" },
  { name: "3", label: "UX/UI Designer" },
];

export const publicSampleResumeNames = {
  "1": "Janet Ribbonova",
  "2": "Alex Johnson",
  "3": "Lily Smith",
};
