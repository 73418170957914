import { AlertTriangleIcon } from "Icons/AlertTriangle";
import { ChevronDownIcon } from "Icons/ChevronDown";
import { ChevronUpIcon } from "Icons/ChevronUp";
import { InfoCircleIcon } from "Icons/InfoCircle";
import { Lightbulb02 } from "Icons/Lightbulb02";
import { Lock01Icon } from "Icons/Lock01";

import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { SerializedStyles, css } from "@emotion/react";

import {
  SLIDE_TRANSITION_DURATION,
  SLIDE_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { blue, purple, red, yellow } from "constants/colors";

import { Button } from "./Button";
import { Typography } from "./Typography";

const style = {
  toast: css({
    padding: 16,
    borderRadius: 12,
    display: "flex",
    gap: 12,

    "& .collapsable-rectangle-toast-enter": {
      maxHeight: 0,
    },
    "& .collapsable-rectangle-toast-enter-active": {
      maxHeight: 368,
      transition: `max-height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
    "& .collapsable-rectangle-toast-exit": {
      maxHeight: 368,
    },
    "& .collapsable-rectangle-toast-exit-active": {
      maxHeight: 0,
      transition: `max-height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
    overflow: "hidden",
  }),
  info: css({
    background: purple[100],
    border: `1px solid${purple[600]}`,
  }),
  error: css({
    background: red[100],
    border: `1px solid${red[600]}`,
  }),
  tip: css({
    background: blue[100],
  }),
  warning: css({
    background: yellow[200],
    border: `1px solid${yellow[500]}`,
  }),
  header: css({
    display: "flex",
    justifyContent: "space-between",
  }),
  iconAndTitle: css({
    display: "flex",
    gap: 12,
    alignItems: "center",
    "& svg": {
      flexShrink: 0,
    },
  }),
};

type Props = {
  children?: React.ReactNode;
  customCss?: SerializedStyles;
  toastType?: "warning" | "info" | "locked" | "tip" | "error";
  title?: string;
  isCollapsible?: boolean;
  isInitiallyCollapsed?: boolean;
};

const titleColorMap = {
  tip: blue[600],
  error: red[700],
  warning: purple[600],
  locked: purple[600],
  info: purple[600],
};

export const RectangleToast = ({
  customCss,
  toastType = "info",
  children,
  title,
  isCollapsible,
  isInitiallyCollapsed,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(!!isInitiallyCollapsed);
  const nodeRef = useRef(null);

  return (
    <div
      css={[
        style.toast,
        toastType === "info" && style.info,
        toastType === "locked" && style.info,
        toastType === "warning" && style.warning,
        toastType === "tip" && style.tip,
        toastType === "error" && style.error,
        customCss,
      ]}
    >
      <div css={style.header}>
        <div css={style.iconAndTitle}>
          {toastType === "warning" && <AlertTriangleIcon />}
          {toastType === "info" && (
            <InfoCircleIcon stroke={purple[600]} width={16} height={16} />
          )}
          {toastType === "error" && (
            <InfoCircleIcon stroke={red[600]} width={16} height={16} />
          )}
          {toastType === "tip" && (
            <Lightbulb02 stroke={blue[600]} width={16} height={16} />
          )}
          {toastType === "locked" && <Lock01Icon stroke={purple[600]} />}
          <Typography
            size={toastType === "tip" ? "XS" : "S"}
            weight={toastType === "tip" ? "bold" : "medium"}
            color={titleColorMap[toastType]}
          >
            {title}
          </Typography>
        </div>
        {isCollapsible && (
          <Button
            IconLeft={isCollapsed ? ChevronDownIcon : ChevronUpIcon}
            color="gray"
            size="small"
            onClick={() => setIsCollapsed(!isCollapsed)}
            ariaLabel={isCollapsed ? "Expand" : "Collapse"}
          />
        )}
      </div>

      {children && (
        <CSSTransition
          nodeRef={nodeRef}
          in={!isCollapsed || !isCollapsible}
          timeout={200}
          unmountOnExit
          classNames="collapsable-rectangle-toast"
        >
          <div ref={nodeRef}>{children}</div>
        </CSSTransition>
      )}
    </div>
  );
};
